<template>
  <footer class="footer">
      <!-- <img class="logo-footer" src="@/assets/images/logo.png" alt="" /> -->
      <p class="copyright">
        盈客云—上海尔骋科技有限公司 |
        <a target="_blank" class="beian-link" href="https://beian.miit.gov.cn/">
          沪ICP备20011268号-3
        </a>
        |
        <a
          target="_blank"
          class="beian-link"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010402351499"
          >本网站支持 IPv6 上海尔骋科技有限公司</a
        >
      </p>
  </footer>
</template>

<script>
export default {
  name: "myFooter",
};
</script>

<style>
</style>