import router from '@/router';
import { Message, MessageBox } from 'element-ui';
import { clearCookies, getCookie } from './utils/cookie';
import store from './store';
import { getUserInfo } from './api/user';
const login_token = getCookie('login_token')
const access_token = getCookie('access_token')

console.log(login_token);
router.beforeEach((to, from, next) => {
  if (login_token) {
    next();
    if (store.state.userInfo === '{}') {
      getUserInfo({
        login_token: login_token,
        access_token: access_token
      }).then(res => {
        if (res.data.code === 0) {
          store.commit('setUserInfo', JSON.stringify(res.data.data))
        } else {
          Message.error(res.data.msg)
        }
      })
    }
  } else {
    const meta = to.meta || {};
    if (meta.isAuth === false) {
      next();
    } else {
      // next();
      MessageBox.alert('令牌状态已过期，请点击重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        type: 'warning',
      })
        .then(() => {
          clearCookies()
          setTimeout(() => {
            if(process.env.NODE_ENV=='development'){
              location.href = 'https://www.chatexcel.com'
            }else{
              location.href = 'https://www.chatexcel.com'
            }
            
          }, 1000)
        })
        .catch(() => { });
    }
  }
});

router.afterEach(() => {
});
