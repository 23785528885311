import request from '@/utils/axios'
import { getCookie } from '@/utils/cookie'
var qs = require('qs');



export function getUserInfo(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/user/info' },
        data
    })
}
export function getAccessToken() {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/auth/get-access-token' },
        headers: {
            isToken: false,
        },
        data: {
            appid: '5NnDQmEv94ZLNVA',
            appsecret: "1nrhJKzmK0tsq1SkPBqVqJ8tA4bhVTnQ"
        }
    })
}
export function mealTypeList(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/meal/type-list' },
        headers: {
            isToken: false,
        },
        data
    })
}


export function mealList(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/meal/list' },
        headers: {
            isToken: false,
        },
        data
    })
}

export function userBuyMeal(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/user/buy-meal' },
        headers: {
            isToken: false,
        },
        data
    })
}

export function userSendMsg(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/user/send-msg' },
        data: data
    })
}

export function resetPassWord(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/user/reset-pass-word' },
        data: data
    })
}
export function remembResetPassWord(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/user/rememb-reset-pass-word' },
        data: data
    })
}



export function conversationLog(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/user/conversation-log' },
        data: data
    })
}
export function getConversationLog(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/user/get-conversation-log' },
        data: data
    })
}



// 获取表
export function getTable(data) {
    return request({
        url: '/api2/get_table',
        method: 'get',
        data,
    })
}
// 设置表
export function setTable(data) {
    return request({
        url: '/api2/set_table',
        method: 'post',
        data
    })
}

// 提问
export function uploadQuery(data) {
    return request({
        url: '/api2/upload_query',
        method: 'post',
        // responseType: 'blob',
        headers: {
            Accept: "application/stream+json",
        },
        data
    })
}
export function cartRoomQuery(data) {
    return request({
        url: '/api5/api/v1/prediction/4d0b5d56-a4d7-416c-aa45-7eab92f0b8f6',
        method: 'post',
        // responseType: 'blob',
        headers: {
            Accept: "application/json",
        },
        data
    })
}
export function getChatflows(data) {
    return request({
        url: '/api5/api/v1/chatflows/' + data.id,
        method: 'get',
        // responseType: 'blob',
        headers: {
            Accept: "application/json",
        },
        data
    })
}
export function putChatflows(data) {
    return request({
        url: '/api5/api/v1/chatflows/' + data.id,
        method: 'put',
        // responseType: 'blob',
        headers: {
            Accept: "application/json",
        },
        data
    })
}

export function postPrediction(data) {
    return request({
        url: '/api5/api/v1/prediction/' + data.id,
        method: 'post',
        // responseType: 'blob',
        headers: {
            Accept: "application/json",
        },
        data
    })
}

// 清除缓存
export function clearCache(data) {
    return request({
        url: '/api2/clear_cache',
        method: 'post',
        data
    })
}

// 清除用户缓存
export function clearUserCache(data) {
    return request({
        url: '/api2/clear_user_cache',
        method: 'post',
        data
    })
}


// 重新query
export function regenerate(data) {
    return request({
        url: '/api2/regenerate',
        method: 'post',
        data
    })
}

export function getInstances(data) {
    return request({
        url: '/api2/get_instances',
        method: 'get',
        data
    })
}
export function sessionCopy(data) {
    return request({
        url: '/api2/session_copy',
        method: 'post',
        data
    })
}
export function getSessionByList(data) {
    return request({
        url: '/api2/get_session_by_list',
        method: 'post',
        data
    })
}
export function restoreConversation(data) {
    return request({
        url: '/api2/restore_conversation',
        method: 'post',
        data
    })
}
export function getAgentList(data) {
    return request({
        url: '/api3/GetAgentList',
        method: 'post',
        data: data
    })
}
export function getLoginSession(data) {
    return request({
        url: '/api3/GetLoginSession',
        method: 'post',
        data: data
    })
}
export function startAgent(data) {
    return request({
        url: '/api3/StartAgent',
        method: 'post',
        data: data
    })
}
export function describeDesktop(data) {
    return request({
        url: '/api3/DescribeDesktop',
        method: 'post',
        data: data
    })
}
export function getAgent(data) {
    return request({
        url: '/api3/GetAgent',
        method: 'post',
        data: data
    })
}
export function stopAgent(data) {
    return request({
        url: '/api3/StopAgent',
        method: 'post',
        data: data
    })
}



export function startDesktop(data) {
    return request({
        url: '/api3/StartDesktop',
        method: 'post',
        data: data
    })
}
export function deleteAgent(data) {
    return request({
        url: '/api3/DeleteAgent',
        method: 'post',
        data: data
    })
}
export function getAgentLog(data) {
    return request({
        url: '/api3/GetAgentLog',
        method: 'post',
        data: data
    })
}
export function getAgentFile(data) {
    return request({
        url: '/api3/GetAgentFile',
        method: 'post',
        responseType: 'blob',
        // 'Content-Type': ContentType
        contentType: 'application/json',
        //  AxiosHeaderValue | 'text/html' | 'text/plain' | 'multipart/form-data' | 'application/json' | 'application/x-www-form-urlencoded' | 'application/octet-stream';
        data: data
    })
}

export function chat_login(data) {
    return request({
        url: '/api4/v1/user/chat_login',
        method: 'post',
        data: data
    })
}
export function upload_files(data) {
    return request({
        url: '/api4/v1/user/upload_files',
        method: 'post',
        data: data
    })
}
export function user_dialogue(data) {
    return request({
        url: '/api4/v1/user/user_dialogue',
        method: 'post',
        data: data
    })
}


// 上传任务文件
export function uploadFile(data) {
    return request({
        url: '/api3/UploadFile',
        method: 'post',
        data: data
    })
}

// 下载任务文件
export function downloadFile(data) {
    return request({
        url: '/api3/DownloadFile',
        method: 'post',
        responseType: 'blob',
        data

    })
}

// 获取任务文件列表
export function getFileList(data) {
    return request({
        url: '/api3/GetFileList',
        method: 'post',
        data: data
    })
}

// 删除任务文件
export function deleteFile(data) {
    return request({
        url: '/api3/DeleteFile',
        method: 'get',
        data
    })
}

// 上传用户文件
export function uploadUserFile(data) {
    return request({
        url: '/api3/UploadUserFile',
        method: 'post',
        data
    })
}

// 创建用户文件夹
export function createUserDir(data) {
    return request({
        url: '/api3/CreateUserDir',
        method: 'post',
        data
    })
}

// 下载用户文件
export function downloadUserFile(data) {
    return request({
        url: '/api3/DownloadUserFile',
        method: 'post',
        //  responseType: 'blob',
        // // 'Content-Type': ContentType
        // contentType: 'application/json',
        data
    })
}

// 获取用户文件列表
export function getUserFileList(data) {
    return request({
        url: '/api3/GetUserFileList',
        method: 'post',
        data
    })
}

// 删除用户文件
export function deleteUserFile(data) {
    return request({
        url: '/api3/DeleteUserFile',
        method: 'post',
        data
    })
}
// 获取任务列表
export function getTaskList(data) {
    return request({
        url: '/api3/GetTaskList',
        method: 'post',
        data
    })
}
// 创建任务
export function createTask(data) {
    return request({
        url: '/api3/CreateTask',
        method: 'post',
        data
    })
}
// 获取任务详情
export function getTaskdetail(data) {
    return request({
        url: '/api3/GetTask',
        method: 'post',
        data
    })
}
// 获取任务详情
export function GetInputFileList(data) {
    return request({
        url: '/api3/GetInputFileList',
        method: 'post',
        data
    })
}
// 获取任务flow
export function getTaskLog(data) {
    return request({
        url: '/api3/GetTaskLog',
        method: 'post',
        data
    })
}
// 删除任务
export function deleteTask(data) {
    return request({
        url: '/api3/DeleteTask',
        method: 'post',
        data
    })
}
// 挂起任务
export function submitTask(data) {
    return request({
        url: '/api3/SubmitTask',
        method: 'post',
        data
    })
}
// 更新任务
export function updateTask(data) {
    return request({
        url: '/api3/UpdateTask',
        method: 'post',
        data
    })
}
// 获取任务文件

export function getTaskFile(data) {
    return request({
        url: '/api3/GetTaskFile',
        method: 'post',
        responseType: 'blob',
        contentType: 'application/json',
        data
    })
}

// 重新query
export function customerService(data) {
    return request({
        url: '/api6/CustomerService',
        method: 'post',
        data
    })
}
// 获取所有Category

export function getCategoryList(data) {
    return request({
        url: '/api3/GetCategoryList',
        method: 'post',
        data
    })
}
// 发布
export function releaseTemplet(data) {
    return request({
        url: '/api3/ReleaseTemplet',
        method: 'post',
        data
    })
}
// 获取模版数据
export function getTempletList(data) {
    return request({
        url: '/api3/GetTempletList',
        method: 'post',
        data
    })
}
// 下载模版

export function copyTemplet(data) {
    return request({
        url: '/api3/CopyTemplet',
        method: 'post',
        data
    })
}
// 任务输出
export function getTaskOutput(data) {
    return request({
        url: '/api3/GetTaskOutput',
        method: 'post',
        data
    })
}
// 获取创建云桌面状态
export function getCreateDesktop(data) {
    return request({
        url: '/api3/GetCreateDesktop',
        method: 'post',
        data
    })
}

// 团队管理列表
export function getUserlist(data) {
    return request({
        url: '/api1/?r=v1/team/user-list',
        method: 'post',
        data
    })
}
// 团队套餐账号详情
export function getMeaInfo(data) {
    return request({
        url: '/api1/?r=v1/team/meal-info',
        method: 'post',
        data
    })
}
// 团队管理列表--删除
export function delUser(data) {
    return request({
        url: '/api1/?r=v1/team/user-del',
        method: 'post',
        data
    })
}
// 团队管理列表--新建编辑
export function userInvite(data) {
    return request({
        url: '/api1/?r=v1/team/user-invite',
        method: 'post',
        data
    })
}
// 团队管理列表--邮件邀请
export function userInvitationEmail(data) {
    return request({
        url: '/api1/?r=v1/team/send-invitation-email',
        method: 'post',
        data
    })
}
// 团队管理列表--邮件邀请
export function userInvitationMobilel(data) {
    return request({
        url: '/api1/?r=v1/team/send-invitation-mobile',
        method: 'post',
        data
    })
}
// 团队管理列表--空间分配数据
export function allocationInfo(data) {
    return request({
        url: '/api1/?r=v1/team/allocation-info',
        method: 'post',
        data
    })
}
// 团队管理列表--空间分配保存
export function saveAllocation(data) {
    return request({
        url: '/api1/?r=v1/team/allocation',
        method: 'post',
        data
    })
}
// 邮箱验证码
export function sendEmailCode(data) {
    return request({
        url: '/api1/?r=v1/user/send-email',
        method: 'post',
        data
    })
}
//更换手机号
export function updateMobile(data) {
    return request({
        url: '/api1/?r=v1/user/update-mobile',
        method: 'post',
        data
    })
}
//更换邮箱
export function updateEmail(data) {
    return request({
        url: '/api1/?r=v1/user/update-email',
        method: 'post',
        data
    })
}
//获取tool列表
export function myToolList(data) {
    return request({
        url: '/api1/?r=v1/user/my-tool-list',
        method: 'post',
        data
    })
}
// 团队空间
export function teamSpace(data) {
    return request({
        url: '/api1/?r=v1/user/team-space',
        method: 'post',
        data
    })
}
// 个人空间
export function personalSpace(data) {
    return request({
        url: '/api1/?r=v1/user/personal-space',
        method: 'post',
        data
    })
}
// 标签列表
export function labelList(data) {
    return request({
        url: '/api1/?r=v1/tool/label-list',
        method: 'post',
        data
    })
}
// 保存bot
export function botSave(data) {
    return request({
        url: '/api1/?r=v1/tool/save',
        method: 'post',
        data
    })
}
// boss后台维护的类目数据
export function categoryList(data) {
    return request({
        url: '/api1/?r=v1/tool/category-list',
        method: 'post',
        data
    })
}
// 我的bot列表
export function botList(data) {
    return request({
        url: '/api1/?r=v1/user/bot-list',
        method: 'post',
        data
    })
}

// bot详情
export function botInfo(data) {
    return request({
        url: '/api1/?r=v1/user/bot-info',
        method: 'post',
        data
    })
}

// 公有黑名单
export function getPublicBlackWords(data) {
    return request({
        url: '/api3/GetPublicBlackWords',
        method: 'post',
        data
    })
}
// 私有黑名单
export function getPrivateBlackWords(data) {
    return request({
        url: '/api3/GetPrivateBlackWords',
        method: 'post',
        data
    })
}

// 添加私有黑名单数据
export function addPrivateBlackWords(data) {
    return request({
        url: '/api3/AddPrivateBlackWords',
        method: 'post',
        data
    })
}
// 更新私有黑名单数据
export function updatePrivateBlackWords(data) {
    return request({
        url: '/api3/UpdatePrivateBlackWords',
        method: 'post',
        data
    })
}
// 删除私有黑名单数据
export function delPrivateBlackWords(data) {
    return request({
        url: '/api3/DelPrivateBlackWords',
        method: 'post',
        data
    })
}

// bot删除
export function botDel(data) {
    return request({
        url: '/api1/?r=v1/user/bot-del',
        method: 'post',
        data
    })
}
// 获取会话列表
export function getChatList(data) {
    return request({
        url: '/api3/GetChatList',
        method: 'post',
        data
    })
}

// 获取会话详情数据
export function getMessage(data) {
    return request({
        url: '/api3/GetMessage',
        method: 'post',
        data
    })
}
// 获取关联问题
export function getRecommend(data) {
    return request({
        url: '/api6/GetRecommend',
        method: 'post',
        data
    })
}
// 更新会话标题
export function updateChatTitle(data) {
    return request({
        url: '/api3/UpdateChatTitle',
        method: 'post',
        data
    })
}
// 删除会话
export function deleteChat(data) {
    return request({
        url: '/api3/DeleteChat',
        method: 'post',
        data
    })
}

// 社媒接口
// 
// 创建社媒
export function createMedia(data) {
    return request({
        url: '/api3/CreateMedia',
        method: 'post',
        data
    })
}
// 获取社媒列表
export function getMediaList(data) {
    return request({
        url: '/api3/GetMediaList',
        method: 'post',
        data
    })
}
// 社媒第一次生成并存储信息
export function generateMediaMessage(data) {
    return request({
        url: '/api3/GenerateMediaMessage',
        method: 'post',
        data
    })
}
// 获取营销方案信息
export function getMarketMessage(data) {
    return request({
        url: '/api3/GetMarketMessage',
        method: 'post',
        data
    })
}
// 获取子方案id
export function getSubMediaList(data) {
    return request({
        url: '/api3/GetSubMediaList',
        method: 'post',
        data
    })
}
// 获取生成进度
export function getFeatureNums(data) {
    return request({
        url: '/api3/GetFeatureNums',
        method: 'post',
        data
    })
}

// 洞察接口
export function getInsightMessage(data) {
    return request({
        url: '/api3/GetInsightMessage',
        method: 'post',
        data
    })
}
// 舆情接口
export function getOpinionMessage(data) {
    return request({
        url: '/api3/GetOpinionMessage',
        method: 'post',
        data
    })
}
// 达人接口
export function getDeliveryMessage(data) {
    return request({
        url: '/api7/GetDeliveryMessage',
        method: 'post',
        data
    })
}
// 删除分析数据
export function deleteMedia(data) {
    return request({
        url: '/api7/DeleteMedia',
        method: 'post',
        data
    })
}
// 单个模块生成接口
export function generateMarketMessage(data) {
    return request({
        url: '/api7/GenerateMarketMessage',
        method: 'post',
        data
    })
}
export function generateInsightMessage(data) {
    return request({
        url: '/api7/GenerateInsightMessage',
        method: 'post',
        data
    })
}
export function generateOpinionMessage(data) {
    return request({
        url: '/api7/GenerateOpinionMessage',
        method: 'post',
        data
    })
}
export function generateDeliveryMessage(data) {
    return request({
        url: '/api7/GenerateDeliveryMessage',
        method: 'post',
        data
    })
}
// 补数据接口
export function supplydata(data) {
    return request({
        url: '/api7/Supplydata',
        method: 'post',
        data
    })
}
// 获取补数进度
export function getSupplydata(data) {
    return request({
        url: '/api7/GetSupplydata',
        method: 'post',
        data
    })
}
//获取单个生成进度     
export function getMarketNums(data) { 
    return request({
        url: '/api7/GetMarketNums',
        method: 'post',
        data
    })
}
export function getInsightNums(data) {
    return request({
        url: '/api7/GetInsightNums',
        method: 'post',
        data
    })
}
export function getOpinionNums(data) {
    return request({
        url: '/api7/GetOpinionNums',
        method: 'post',
        data
    })
}
export function getDeliveryNums(data) {
    return request({
        url: '/api7/GetDeliveryNums',
        method: 'post',
        data
    })
}
// 获取关联作品
export function getRelatedNotes(data) {
    return request({
        url: '/api7/GetRelatedNotes',
        method: 'post',
        data
    })
}
// 获取用户订单
export function getUserOrder(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/meal/get-user-order-list' },
        data: data
    })
}
// 获取用户剩余套餐
export function getUserMeal(data) {
    return request({
        url: '/api1',
        method: 'post',
        params: { r: 'v1/meal/get-user-meal-residue' },
        data: data
    })
}
// url: '/api6/CustomerService',
// FromData请求
export const defaultData = (url, params, method) => {
    // let urls=process.env.NODE_ENV=== 'production' ?url:'/api'+url
    return new Promise((resolve, reject) => {
        if (!params) {
            params = {}
        }
        //   let data = qs.stringify(params);
        let data = params
        return request({
            data,
            url: '/api6/' + url,
            method: method || "post"
        }).then(res => {
            console.log(res)
            if (res.status == 200) {
                if (res.data.code != 10010 && res.data.code != 1) {
                    resolve(res)
                } else if (res.data.code == 1) {
                    // 接口一些错误信息
                    resolve(res)
                } else {
                    // _this.$notify({
                    //   message: res.data.msg,
                    //   color: '#fff',
                    // });
                }
            } else {
                reject(res)
            }
        }).catch(err => {
            // _this.$notify({
            //   message: '服务器异常，请稍后重试',
            //   color: '#fff',
            // });
            reject(err)
        })
    })

};
// 手动上传文件
export const uploadFiles = (url, params, method) => {
    var formData = new FormData()
    for (let k in params) {
        formData.append(k, params[k])
    };
    return new Promise((resolve, reject) => {
        return request({
            data: formData,
            url: '/api1/' + url,
            method: method || 'post',
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.status == 200) {
                if (res.data.code != 10010) {
                    resolve(res.data)
                }
            } else {
                reject(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}