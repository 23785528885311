import store from '@/store';
import axios from 'axios'
import { apis } from '@/const/xhr'
import { getAccessToken } from '@/api/user';
import { Message, MessageBox } from 'element-ui';
import { clearCookies } from './cookie';

// axios.defaults.timeout = 30000;
// axios.defaults.baseURL = 'api/'; 
// 定义一个变量，用于标记 token 刷新的状态
let isRefreshing = false;
let refreshSubscribers = [];
// request拦截
axios.interceptors.request.use(
    config => {
      
        if (config.url.indexOf("/api2") === 0) {
            config.url = config.url.replace("/api2", apis.api2);
        } else if (config.url.indexOf("/api3") === 0) {
            config.url = config.url.replace("/api3", apis.api3);
        } else if (config.url.indexOf("/api4") === 0) {
            config.url = config.url.replace("/api4", apis.api4);
        } else if (config.url.indexOf("/api5") === 0) {
            config.url = config.url.replace("/api5", apis.api5);
        }else if (config.url.indexOf("/api6") === 0) {
            config.url = config.url.replace("/api6", apis.api6);
        }else if (config.url.indexOf("/api7") === 0) {
            config.url = config.url.replace("/api7", apis.api7);
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//  response拦截
axios.interceptors.response.use(
    async (res) => {
        // console.log(res,'redredred');
        if (res.data.code === 10005) {
            clearCookies()
            Message.error('令牌状态已过期！')
            setTimeout(() => {
                if(process.env.NODE_ENV=='development'){
                    location.href = 'https://www.chatexcel.com'
                  }else{
                    location.href = 'https://www.chatexcel.com'
                  }
            }, 800)
        } else if (res.data.code === 10004 || res.data.code === 10001) {
            if (!isRefreshing) {
                isRefreshing = true;
                // 发起刷新 token 的请求
                const resule = await getAccessToken();
                isRefreshing = false
                const newToken = resule.data.data.access_token
                store.commit('setToken', resule.data.data.access_token)

                // 刷新 token 完成后，重新发送之前失败的请求
                res.config.data = {
                    ...JSON.parse(res.config.data),
                    access_token: newToken
                }
                refreshSubscribers.forEach((cb) => cb(newToken));
                refreshSubscribers = [];
                return axios(res.config);
            } else {
                // 返回未执行 resolve 的 Promise
                return new Promise((resolve) => {
                    refreshSubscribers.push((newToken) => {
                        // 刷新 token 完成后，重新发送之前失败的请求
                        res.config.data = {
                            ...JSON.parse(res.config.data),
                            access_token: newToken
                        }
                        // 用函数形式将 resolve 存入，等待刷新后再执行
                        resolve(axios(res.config));
                    });
                });
            }
        } else if (res.status !== 200 || res.data.code === 1) {
            // console.log('1');
            const text = res.data.msg
            Message({ message: text, type: 'error' });
            return Promise.reject(res);
        } else if (res.data.code === 0 || res.data.status === 200 || res.status || res.status == 200) {
            // console.log('2');
            return Promise.resolve(res);
        } else {
            // console.log('3');
            return Promise.reject(res);
        }
    },
    error => {
        Message.error(error.message)
        return Promise.reject(error);
    }
);

export default axios;
