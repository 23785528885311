<template>
  <el-container>
    <el-aside width="240px" style="height: 100vh">
      <myAside />
    </el-aside>
    <el-container style="height: 100vh;">
      <el-header style="padding-left: 14px;">
        <myHeader />
      </el-header>
      <el-main style="height: calc(100vh - 60px);">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import myHeader from "./myHeader";
import myAside from "./myAside";
import myFooter from "../components/myFooter";
export default {
  components: { myHeader, myAside, myFooter },
};
</script>

<style scoped>
.el-aside {
  background: rgba(248, 249, 250, 1);
}

.el-header {
  border-bottom: 1px solid rgba(226, 226, 226, 1);
}

.el-main {
  background-color: #fff;
  padding: 0;
  margin: 0;
}
</style>