import { getConversationLog, getInstances, getSessionByList } from '@/api/user'
import { getCookie, setCookie } from '@/utils/cookie'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: getCookie('access_token') || '',
    login_token: getCookie('login_token') || '',
    mobile: getCookie('mobile') || '',
    pwd: getCookie('pwd') || '',
    purchaseDisplay: false,
    userInfo: getCookie('userInfo') || '{}',
    sessionIDs: [],
    menus: [],
    language: getCookie('language') || 'zh_CN'
  },
  getters: {
  },

  mutations: {
    setLanguage(state, active) {
      state.language = active
      setCookie('language', active)
    },
    setToken(state, active) {
      state.access_token = active
      setCookie('access_token', active)
    },
    setLoginDisplay(state, active) {
      state.purchaseDisplay = active
    },
    setloginToken(state, active) {
      state.login_token = active
      setCookie('login_token', active)
    },
    setUserInfo(state, active) {
      state.userInfo = active
      setCookie('userInfo', active)
    },
    SET_SSIONSIDS(state, active) {
      state.sessionIDs = active

    },
    SET_MENUS(state, active) {
      state.menus = active

    }
  },
  actions: {
    conversationLog({ commit }, userInfo) {
      getConversationLog({
        login_token: getCookie('login_token'),
        access_token: getCookie('access_token'),
      }).then(res => {
        getSessionByList({ sessionIDs: res.data.data }).then(metadata => {
          const sessionByList = metadata.data.data
          if (sessionByList) {
            commit('SET_SSIONSIDS', sessionByList);
          }
        })
      })
    },
    getMenus({ commit }, userInfo) {
      getInstances().then((res) => {
        if (res.data.status == 200) {
          commit('SET_MENUS', res.data.data);
        }
      });
    }

  },
  modules: {
  }
})
