export default {
    global: {
        home: 'Home',
        tools: 'Tools',
        bot: 'Bot',
        data: 'Data',
        templates: 'Templates',
        analysis: 'Analysis',
        tutorials: 'Tutorials',
        myForm:'MyForm',
        APIkeys: 'API keys',
        document: 'Document',
        waitlist: 'Waitlist for Prompt Studio',
        needHelp: 'Need help? Talk to our team',
        joinTheYuanKong: 'Join the YuanKong AI community',
        back: 'Back',
        createBot: 'Create bot',
        botName: 'BotName',
        botDescription: 'Bot description',
        createLabel: 'Please create a label for the robot',
        workflow: 'Workflow',
        editWorkflow: 'Edit Workflow',
        Cards: "Cards",
        Videos: 'Videos',
        Edit: 'Edit',
        implement: 'Implement',
        currenStep: 'Currently executing step 5 /12',
        viewLog: 'View log',
        watchExplainer: 'Watch explainer',
        trytool: 'Try tool',
        conversationalTools: 'Provide rich and professional AI conversational tools',
        analyseExcelAndCSVData: 'Analyse excel/CSV data, please be specific about your question',
        analyseBeautyData: 'Analyse Beauty data demo, please be specific about your question',
        popular: 'Popular',
        builderCenter: 'Or, create your AI workforce in our Builder Center',
        deployBots: 'Deploy one of our starter bots',
        IntroducingTheBDRAgent: 'Introducing the BDR Agent',
        launched: 'We’ve launched our first flagship AI agent that works as an extension of your sales team.',
        joinOurTeam: 'Join Our Team',
        createNew: 'Create New',
        watchVideo: 'Watch Video',
        deployRobot: "Deploy robot",
        usageData: 'Usage Data',
        BeautyPublicDomainData: 'Beauty public domain data',
        createData: 'Create data',
        OfficialDataSource: 'Official data source',
        newChat: 'New Chat',
        youCanAskMeQuestionsRelatedToTheAboveData: 'You can ask me questions related to the above data...',
        HiWelcomeToUseYuankongAI: 'Hi~, welcome to use Yuankong AI'
    },
       // 主页--配置
    startPage: {
        top_swiper:[
            {
                title:'Manipulate your Excel spreadsheet solely through chat',
                illustrate:'The choice of over 2000W users worldwide'
            },
            // {
            //     title:'Manipulate your Excel spreadsheet solely through chat2',
            //     illustrate:'The choice of over 2000W users worldwide2'
            // }
        ],
        table_config:{
            h4:'My commonly used ChatExcel spreadsheets',
            btn:'Create Table'
        },
        tableColumn:['Recent Tables','Responsible person','Last opened time'],
        can_do_config: {
            h4: 'You can use ChatExcel to do these things',
            btn: 'See more'
        },
        dosomething_config:[
            {
                img:'img_1',
                tit_p:'Sorting of Chaotic Data',
                instruct_tit:'Command word template',
                instruct_span1:'Sort in ascending order by the first letter of the contact email address',
                instruct_span2:'Email domain names are sorted in ascending order, with the same domain names sorted by the first letter of the prefix',
                btn:'Chaotic Data Sorting Practice Table'
            },
            {
                img:'img_2',
                tit_p:'Batch translation of Chinese to English content',
                instruct_tit:'Command word template',
                instruct_span1:'Add a new column as "Food English"',
                instruct_span2:'Translate all "specialty foods" into English and add the results to the "English column"',
                btn:'Batch Chinese to English Content Practice Table'
            },
            {
                img:'img_3',
                tit_p:'Cleaning and screening to develop data',
                instruct_tit:'Command word template',
                instruct_span1:'Descending order of GDP',
                instruct_span2:'Top 5 provinces in GDP/5-10 provinces in GDP ranking',
                btn:'Cleaning and screening to develop data practice forms'
            },
            {
                img:'img_4',
                tit_p:'Basic operation - summation',
                instruct_tit:'Command word template',
                instruct_span1:'Add the 2022 GDP of "Yunnan Province", "Guangdong Province" and "Tianjin" together',
                instruct_span2:'Add up the 2022 GDP of provinces and cities north of the Qinling and Huaihe Rivers',
                btn:'Basic Operations - Sum Exercise Table'
            }
        ]
    },
    // 我的表格页面
    myFormPage:{
        title:'Quickly Create',
        change_file:'Select local file',
        import_file:'Import exercise form',
        common_use:'My commonly used ChatExcel spreadsheets',
        tableColumn: ['Recent Tables', 'Responsible person', 'Last opened time','operate'],
    }   
}