<template>
  <el-dialog
    class="pubilc-pay-dialog"
    :visible="visible"
    :before-close="handleClose"
    width="40%"
    title="企业对公转账"
  >
    <div class="el-dialog__body">
      <div class="pay-subject">
        <p>账户名称：北京元空智能科技有限公司</p>
        <p class="mt8">账户号码：110060577013005952460</p>
        <p class="mt8">开户银行：交通银行股份有限公司北京北太平庄支行</p>
      </div>
      <div class="pay-tips">
        <p>
          1.受银行处理时间影响，采用对公转账方式到账会有延误，强烈建议采用在线支付。
        </p>
        <p class="mt8">
          2.对公转账到账时间一般为：建行1-2天，跨行3-5天（具体到账时间以银行的实际到账时间为准）。
        </p>
        <p class="mt8">
          3.请通过网银转账，或者自行到银行进行转账，转账成功后请将汇款底单拍照或扫描发送给客服。
        </p>
        <p class="mt8">
          4.确认汇款到账后为您开通相关帐号功能，账号开通后可在后台用户中心索取发票。
        </p>
      </div>
      <div class="flex items-center justify-center mt20">
        <el-button @click="handleClose" type="primary">我已知悉</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style>
.pubilc-pay-dialog {
  line-height: 1;
}

.pubilc-pay-dialog .el-dialog .el-dialog__header {
  padding: 24px 24px 20px;
  border-bottom: none;
  line-height: 1;
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: #333;
  text-align: center;
}

.pubilc-pay-dialog .el-dialog__header .el-dialog__title {
  font-size: 16px;
  text-align: center;
}

.pubilc-pay-dialog .el-dialog__headerbtn {
  top: 24px;
  right: 24px;
  font-size: 14px;
}

.pubilc-pay-dialog .el-dialog__body {
  padding: 0 24px 10px;
  line-height: 1.8;
}

.pubilc-pay-dialog .pay-subject {
  color: #333;
  line-height: 1.6;
  font-weight: 500;
}

.pubilc-pay-dialog .pay-tips {
  margin-top: 20px;
  line-height: 1.25;
  color: #999;
  font-size: 12px;
}

.pubilc-pay-dialog .contact {
  position: absolute;
  right: 24px;
  bottom: 32px;
  line-height: 1;
  color: #307dff;
}

.pubilc-pay-dialog .contact .iconpark-icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.pubilc-pay-dialog .el-dialog__header {
  padding: 24px 24px 20px;
  border-bottom: none;
  line-height: 1;
}
</style>