<template>
  <div id="app">
    <router-view />
    <purchaseDialog v-if="$store.state.purchaseDisplay"></purchaseDialog>
  </div>
</template>
<script>
import purchaseDialog from "@/components/purchaseDialog";
export default {
  components: { purchaseDialog },
  data() {
    return {};
  },
  mounted() {
    console.log(document.cookie);
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 3px; //修改滚动条宽度
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #dcd9d9;
}

//鼠标移入样式
::-webkit-scrollbar-thumb:hover {
  background: #333333;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  height: 100vh;
  display: flex;
}

.flex {
  display: flex;
}

.center {
  align-items: center;
  justify-content: center;
}

.align-center {
  align-items: center;
}

.mr8 {
  margin-right: 8px;
}

.font-size-0 {
  font-size: 0;
}

.mt24 {
  margin-top: 24px;
}

.color-primary {
  color: #307dff;
}

.t-c,
.text-center {
  text-align: center;
}

.el-dialog__wrapper .el-dialog {
  border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-contain {
  background-size: contain;
}

.send-code {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 24px;
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.send-code input[type="button"] {
  border: none;
  background-color: transparent;
  color: #307dff;
  cursor: pointer;
}

.send-code input[type="button"].is-send {
  color: #999;
  cursor: auto;
}

.mt20 {
  margin-top: 20px;
}

.mt8 {
  margin-top: 8px;
}

.justify-center {
  justify-content: center;
}

/* 底部 */
.footer {
  position: fixed;
  background-color: #fff;
  bottom: 0;
  left: 240px;
  width: calc(100% - 240px);
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .logo-footer {
  width: 100.69px;
  height: 27px;
  background-size: contain;
}

.footer .copyright {
  color: #999;
}

.footer .beian-link {
  color: inherit;
  text-decoration: none;
}

.footer .beian-link:hover {
  color: #307dff;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border: 1px solid #00C35E !important;
}</style>
