import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts';
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter) 

const routes = [
  {
    path: '/',
    redirect: '/start',
    component: Layout,
    children: [
      {
        path: '/start',
        name: 'start',
        meta: {
          menu: 'start'
        },
        component: () => import('../views/start/index.vue')
      },
      {
        path: '/tools',
        name: 'tools',
        meta: {
          menu: 'tools'
        },
        component: () => import('../views/tools/index.vue')
      },
      {
        path: '/bot',
        name: 'bot',
        meta: {
          menu: 'bot'
        },
        component: () => import('../views/bot/index.vue')
      },
      {
        path: '/templates',
        name: 'templates',
        meta: {
          menu: 'templates'
        },
        component: () => import('../views/templates/index.vue')
      },
      {
        path: '/analysis',
        name: 'analysis',
        meta: {
          menu: 'analysis'
        },
        component: () => import('../views/analysis/index.vue')
      },
      {
        path: '/agentsAdd',
        name: 'agentsAdd',
        meta: {
          menu: 'bot'
        },
        component: () => import('../views/bot/add.vue')
      },
      {
        path: '/playground',
        name: 'playground',
        meta: {
          menu: 'bot'
        },
        component: () => import('../views/bot/playground.vue')
      },
      {
        path: '/playground_new',
        name: 'playground_new',
        meta: {
          menu: 'bot'
        },
        component: () => import('../views/bot/playground_new.vue')
      },
      
      {
        path: '/task',
        name: 'task',
        meta: {
          menu: 'bot'
        },
        component: () => import('../views/bot/task.vue')
      },
      
      {
        path: '/history',
        name: 'history',
        meta: {
          menu: 'history'
        },
        component: () => import('../views/history/index.vue')
      },
      {
        path: '/personal',
        name: 'personal',
        meta: {
          menu: 'personal'
        },
        component: () => import('../views/personal/index.vue')
      },
      {
        path: '/data',
        name: 'data',
        meta: {
          menu: 'data'
        },
        component: () => import('../views/data/index.vue')
      },
      {
        path: '/session',
        name: 'session',
        meta: {
          menu: 'tools'
        },
        component: () => import('../views/session/index.vue')
      },
      {
        path: '/dataFile',
        name: 'data',
        meta: {
          menu: 'data'
        },
        component: () => import('../views/dataFile/index.vue')
      },
      {
        path: '/chatRoom',
        name: 'chatRoom',
        meta: {
          menu: 'tools'
        },
        component: () => import('../views/chatRoom/index.vue')
      },
      {
        path: '/customerService',
        name: 'customerService',
        meta: {
          menu: 'tools'
        },
        component: () => import('../views/chatRoom/customerService.vue')
      },
      {
        path: '/myForm',
        name: 'myForm',
        meta: {
          menu: 'myForm'
        },
        component: () => import('../views/myForm/index.vue')
      },
      {
        path: '/tutorials',
        name: 'tutorials',
        meta: {
          menu: 'tutorials'
        },
        component: () => import('../views/tutorials/index.vue')
      },
      
      // {
      //   path: '/BlackWords',
      //   name: 'BlackWords',
      //   meta: {
      //     menu: 'data'
      //   },
      //   component: () => import('../views/chatRoom/BlackWords.vue')
      // },
      
    ]
  },
  {
    path: '*',
    name: 'error_404',
    component: () => import('../views/error-page/404.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
