import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import i18n from './i18n'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import 'element-ui/lib/theme-chalk/index.css';
import './permission'
Vue.use(VXETable)

Vue.use(ElementUI)


window.addEventListener('message', function (e) {  // 监听 message 事件
  // console.log('addEventListener-message', e);
  if (e.data == 'cg') {
    router.push({
      path: '/bot'
    })
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
