console.log(process.env.NODE_ENV, window.location.origin, 22);
// betawww.chatexcel.com 测试
// yuankongai  正式
let apis = {}
if (process.env.NODE_ENV == 'development') {
    apis = {
        api1: 'https://www.chatexcel.com/api',
        // api2: 'https://data.yuankong-ai.com', //
        api3: 'https://agent.chatexcel.com', //测试
        // api3: "https://43.153.89.112:5000", //测试
        api4: "http://18.246.191.34:8100",   ///
        api5: "https://aicanvas.chatexcel.com",
        // api6: "https://43.153.89.112:5000",
        api6: "https://app.chatexcel.com",

        // tq测试域名
        api7: "http://139.196.243.222:5005"
    }
} else {
    if (window.location.origin == 'https://workspace.chatexcel.com') {
        apis = {
            api1: 'https://www.chatexcel.com/api',
            // api2: 'https://data.yuankong-ai.com', //
            api3: 'https://agent.chatexcel.com', //测试
            // api3: "https://43.153.89.112:5000", //测试
            api4: "http://18.246.191.34:8100",   ///
            api5: "https://aicanvas.chatexcel.com",
            // api6: "https://43.153.89.112:5000",
            api6: "https://app.chatexcel.com",
            // tq测试域名
            api7: "https://agent.chatexcel.com"
        }
    } else {
        apis = {
            api1: 'https://www.chatexcel.com/api',
            api2: 'https://data.chatexcel.com', //
            api3: 'https://agent.chatexcel.com', //
            // api3: "https://43.153.89.112:5000", //
            api4: "http://18.246.191.34:8100",   ///
            api5: "https://aicanvas.chatexcel.com",
            // api6: "https://43.153.89.112:5000",
            api6: "https://app.chatexcel.com",
            api7: "https://agent.chatexcel.com"
        }
    }

}
export { apis }
