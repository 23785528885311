import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from './lang/zh-CN'
import enUS from './lang/en'
import jaUS from './lang/ja'
import store from '@/store'
// import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
// import enUS from 'vxe-table/lib/locale/lang/en-US'
// import jaJP from 'vxe-table/lib/locale/lang/ja-JP'

Vue.use(VueI18n)

const messages = {
  'zh_CN': {
    ...zhCN
  },
  'en_US': {
    ...enUS
  },
  'ja_US': {
    ...jaUS
  },
}
const lang = store.state.language

const i18n = new VueI18n({
  locale: lang,
  messages,
})

export default i18n
