<template>
  <div class="myHeader">
    <div class="closeAside">
      <img src="@/assets/images/closeAside.png" alt="" style="width: 30px;">
    </div>
    <div style="margin-right: auto;" v-if="$route.meta.menu!='start'">
      <div style="margin-right: auto;font-weight: 600;font-size: 14px;line-height: 14px;"> {{ $route.meta.menu }}</div>
      <div class="remark">Provide professional marketing advice and guidance.</div>
    </div>
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link" style="margin-right: 20px;">
        {{ { zh_CN: '中文', en_US: 'English', ja_US: '日本語' }[$store.state.language] }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="zh_CN">中文</el-dropdown-item>
        <el-dropdown-item command="en_US">English</el-dropdown-item>
        <!-- <el-dropdown-item command="ja_US">日本語</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
    <el-tooltip popper-class="topBar-user-popper" placement="bottom" effect="light">
      <div slot="content">
        <div class="contact-box">暂无数据</div>
      </div>
      <div style="display: flex; align-items: center">
        <i class="el-icon-document" style="font-size: 20px; color: #999; margin-right: 20px"></i>
      </div>
    </el-tooltip>

    <el-tooltip popper-class="topBar-user-popper" placement="top" effect="light">
      <div slot="content">
        <div class="contact-box">
          <img src="@/assets/images/weChatCode.jpg" class="xImg contact-qr" />
          <p class="contact-desc">扫码添加客服微信</p>
          <p class="online-time flex center">在线时间：每日09:00 ~ 18:00</p>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <i class="el-icon-service" style="font-size: 20px; color: #999; margin-right: 20px"></i>
      </div>
    </el-tooltip>

    <el-divider direction="vertical"></el-divider>

    <el-tooltip popper-class="topBar-user-popper" placement="top" effect="light" v-model="disabled">
      <div slot="content" style="width: 350px">
        <div class="weather"></div>
        <div class="user-info-box">
          <img src="@/assets/images/userActive.png" style="width: 25px; height: 25px;margin-right:10px" alt="" />
          <div class="user-info">
            <div>
              <span class="phone">
                {{ JSON.parse($store.state.userInfo).mobile }}</span>
              <span data-v-e35355a0="" class="version-tag version-1 small null">
                <!-- {{ JSON.parse($store.state.userInfo).meal_name }} -->
              </span>
            </div>
            <!-- <p class="expiration-time">
              会员到期时间：{{ JSON.parse($store.state.userInfo).failure_time }}
            </p> -->
          </div>
        </div>
        <el-menu class="menu-box" @select="handleSelect">
          <!-- <el-menu-item index="1">团队管理</el-menu-item> -->
          <el-menu-item index="2">个人中心</el-menu-item>
          <el-menu-item index="3">设置</el-menu-item>
          <!-- <el-menu-item index="1">购买续费</el-menu-item>
          <el-menu-item index="2">个人中心</el-menu-item>
          <el-menu-item index="3">历史记录</el-menu-item> -->
        </el-menu>
        <div class="login-out">
          <span @click="logOut" style="cursor: pointer">退出登录</span>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <img src="@/assets/images/userActive.png" style="width: 30px; height: 30px" alt="" />

        <div style="margin-left: 20px"></div>
      </div>
    </el-tooltip>
    <span style="
            width: 82px;
            height: 24px;
            text-aline: center;
            font-size: 14px;
            color: #09cc96;
            letter-spacing: 0;
            text-align: center;
            line-height: 24px;
            font-weight: 500;
            background: #ddf1eb;
            border-radius: 12px;
            cursor: pointer;
            margin-left: 15px;
          " @click="buyPackage">
      <!-- 用户名隐藏 展示续费套餐 -->
      <!-- {{ JSON.parse($store.state.userInfo).meal_name }} -->
      续费套餐

    </span>
    <el-dialog title="选择套餐" :visible.sync="dialogVisible" width="900px" @close="handleClose">
      <iframe class="wahaha" ref="myframe" @load="onIframeLoad" id="myframe" :src="iframeSrc" frameborder="0" width="800" height="484">
      </iframe>
    </el-dialog>
  </div>
</template>

<script>
import purchaseDialog from "@/components/purchaseDialog";
import { clearCookies } from "@/utils/cookie";
let iframeSrcs = process.env.NODE_ENV === 'development' ? 'http://localhost:8068/#/priceDialog?iframes=true' : 'https://www.chatexcel.com/#/priceDialog?iframes=true'
export default {
  name: "myHeader",
  components: { purchaseDialog },
  data() {
    return {
      disabled: false,
      language: localStorage.getItem('lang'),
      dialogVisible: false,
      iframeSrc: iframeSrcs
    };
  },

  methods: {
    handleCommand(command) {
      this.language = command
      this.$i18n.locale = command // 设置给本地的i18n插件
      console.log(command);
      this.$store.commit("setLanguage", this.language);
      this.$message.success('切换语言成功' + command)
    },
    handleSelect(key) {
      this.disabled = false;
      if (key == "1") {
        this.$router.push({
          path: "/personal",
          query: {
            type: '2'
          }
        });
      } else if (key == "2") {
        this.$router.push({
          path: "/personal",
          query: {
            type: '1'
          }
        });
      } else {
        this.$router.push({
          path: "/personal",
          query: {
            type: '3'
          }
        });
      }
      return
      if (key == "1") {
        this.$store.commit("setLoginDisplay", true);
      } else if (key == "2") {
        this.$router.push({
          path: "/personal",
        });
      } else {
        this.$router.push({
          path: "/history",
        });
      }
    },
    logOut() {
      this.$confirm("此操作将退出当前登录账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clearCookies();
          this.$store.commit("setloginToken", "");
          localStorage.clear();
          setTimeout(() => {
            if (process.env.NODE_ENV == 'development') {
              location.href = 'https://www.chatexcel.com'
            } else {
              location.href = 'https://www.chatexcel.com'
            }
          }, 800);
          this.$message.success("退出成功！");
        })
        .catch(() => { });
    },
    buyPackage() {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.iframeSrc = iframeSrcs

      });
      setTimeout(() => {
        this.sendMessage()
      }, 100);
    },
    sendMessage() {
      const iframe = this.$refs.myframe.contentWindow || this.$refs.myframe.contentDocument.defaultView;
      if (iframe) {
      console.log(this.$refs.myframe.contentDocument)
        console.log(5432345)
        // iframe.postMessage({ action: 'triggerEvent', payload: 'hello' }, '*'); // 发送消息给iframe
      }
    },
    onIframeLoad() {
      // 监听iframe加载完成后的消息
      console.log(5432)
      console.log(this.$refs.myframe.contentDocument)

      // const iframeDocument = this.$refs.myframe.child
      // const body = iframeDocument.body;
      // body.style.width = '500px'; // 设置新的宽度
    },

    handleClose(done) {
      this.iframeSrc = '';
      // this.$nextTick(() => {
      //   this.iframeSrc = 'https://www.chatexcel.com/#/priceDialog?iframes=true';
      // });
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {});
    },
  },
};
</script>

<style scoped>
.wahaha html {
  width: 900px;
}
/deep/ .el-dialog body {
  width: 800px;
}
/deep/ .el-dialog {
  border-radius: 20px;
}
/deep/ .el-dialog__header {
  background: #35ad84;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
.closeAside {
  margin-right: 10px;
  cursor: pointer;
}

.remark {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(127, 134, 146, 1);
}

.myHeader {
  color: black;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.user-info-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
  padding: 16px 0;
  border-bottom: 1px solid #eee;
}

.user-info {
  flex: 1;
  font-size: 12px;
  color: #666;
}

.weather {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  height: 56px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eaf2ff),
    to(#fff)
  );
  background: linear-gradient(180deg, #eaf2ff, #fff);
}

.expiration-time {
  margin-top: 6px;
  white-space: nowrap;
}

.weather::after {
  content: "";
  position: absolute;
  top: 0;
  right: 16px;
  width: 82px;
  height: 56px;
  background: url("@/assets/images/taiyang.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.login-out {
  margin-top: 16px;
  text-align: center;
  line-height: 1;
  font-size: 14px;
}

.contact-box {
  text-align: center;
  line-height: 1;
  font-size: 13px;
}

.contact-qr {
  width: 156px;
  height: 156px;
  border-radius: 8px;
}

.contact-desc {
  margin-top: 16px;
  font-size: 13px;
  color: #333;
}

.online-time {
  margin-top: 16px;
  color: #666;
}

.xImg {
  -o-object-fit: cover;
  object-fit: cover;
}
</style>

<style>
.el-tooltip__popper.is-light.topBar-user-popper {
  box-sizing: border-box;
  margin-top: 16px !important;
  padding: 16px;
  border: none;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.03),
    0 3px 14px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.03), 0 3px 14px rgba(0, 0, 0, 0.06);
  text-align: left;
  overflow: hidden;
}

.menu-box li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  color: #333;
}
</style>